<template>
    <h1 class="inset">Willkommen</h1>
    <p
        class="inset"
    >Auf dieser Website entsteht der ultimative Schultaschenrechner. Es folgen viele weitere Funktionen. Bitte habt Geduld, da die Seite noch in Arbeit ist! (:</p>
    <!-- Basics -->
    <h1 class="inset">Basics</h1>
    <!-- <router-link to="/calculator"> -->
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Taschenrechner</h1>
        </button>
    </router-link>
    <!-- <router-link to="/unit-converter"> -->
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Einheitenumrechner</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Durchschnitt</h1>
        </button>
    </router-link>
    <router-link to="/basic-arithmetic-rules">
        <button class="inset finished-button">
            <h1>Grundrechenregeln</h1>
        </button>
    </router-link>
    <router-link to="/fraction-calculator">
        <button class="inset finished-button">
            <h1>Bruchrechnung</h1>
        </button>
    </router-link>
    <router-link to="/rule-of-three">
        <button class="inset finished-button">
            <h1>Dreisatz</h1>
        </button>
    </router-link>
    <router-link to="/binomial-formulas">
        <button class="inset finished-button">
            <h1>Binomische Formeln</h1>
        </button>
    </router-link>
    <router-link to="/power-laws">
        <button class="inset finished-button">
            <h1>Potenzgesetze</h1>
        </button>
    </router-link>
    <router-link to="/roots">
        <button class="inset finished-button">
            <h1>Wurzeln</h1>
        </button>
    </router-link>
    <router-link to="/logarithm">
        <button class="inset finished-button">
            <h1>Logarithmus</h1>
        </button>
    </router-link>
    <!-- Geometry -->
    <h1 class="inset">Geometrie</h1>
    <!-- <router-link to="/triangle"> -->
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Dreiecke</h1>
        </button>
    </router-link>
    <!-- <router-link to="/figures"> -->
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Figuren und Körper</h1>
        </button>
    </router-link>
    <!-- <router-link to="/intercept-theorem"> -->
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Strahlensatz</h1>
        </button>
    </router-link>
    <!-- Analysis -->
    <h1 class="inset">Analysis</h1>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Wertetabelle und Graphenplotter</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>lineare und exponentielle Funktionen</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Parabelformen und quadratische Ergänzung</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>ganzrationale Funktionen interpolieren</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>e-Funktionen</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Nullstellen</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Schnittpunkte</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Sekanten & Tangenten</h1>
        </button>
    </router-link>
    <router-link to="/triangle">
        <button class="inset undone-button">
            <h1>Ableitung</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Kurvendiskussion</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Integration</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Integrale und Flächen</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Volumina</h1>
        </button>
    </router-link>
    <!-- Linear Algebra -->
    <h1 class="inset">Lineare Algebra</h1>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>LGS & Gauß-Verfahren</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Matrizenrechner</h1>
        </button>
    </router-link>
    <!-- Analytic Geometry -->
    <h1 class="inset">Analytische Geometrie</h1>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Vektoren aufstellen</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Geradengleichung aufstellen</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Ebenengleichung aufstellen</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Winkel zwischen zwei 3-dim. Objekten</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Figuren</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Kreuzprodukt</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Lagebeziehungen zwischen zwei 3-dim. Objekten</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Betrag des Vektors</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Abstände zwischen zwei 3-dim. Objekten</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Ebenenformen umstellen</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Schnittpunkte und Schnittgeraden</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Spiegelungen</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Kugelgleichung</h1>
        </button>
    </router-link>
    <!-- Stochastics -->
    <h1 class="inset">Stochastik</h1>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Erwartungswert</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Binomialverteilung</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Normalverteilung</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Hypothesentest</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Vierfeldertafel und Baumdiagramme</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Bedingte Wahrscheinlichkeit</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Boxplot</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Kombinatorik</h1>
        </button>
    </router-link>
    <router-link to="/">
        <button class="inset undone-button">
            <h1>Histogramme</h1>
        </button>
    </router-link>
</template>

<style lang="scss" scoped>
button {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 220px;
    max-width: 350px;
    border: 1px solid currentColor;
    background-color: transparent;
    vertical-align: top;
    // display: inline-block;
}
.finished-button {
    transition: 0.5s ease-in-out;
    color: #5f3f6f;

    &:before {
        transition: 0.5s ease-out;
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #5f3f6f;
        transform: translateY(100%);
    }

    &:hover {
        color: #d0c7d5;

        &:before {
            transform: translateX(0);
        }
        &:before {
            background-color: #5f3f6f;
        }
    }
}

.undone-button {
    color: lightgrey;
}
</style>
