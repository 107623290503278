<template>
    <h1 class="inset title">Potenzgesetze</h1>
    <p
        class="inset description"
    >Bitte habe noch etwas Geduld. Hier entsteht bald ein passender Rechner.</p>
    <div class="box">
        <h3>Multiplikation</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >a^n \cdot a^m = a^{n + m}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >a^n \cdot b^n = (a \cdot b)^n</math-field>
        </p>
        <h3>Division</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a^n}{a^m} = a^{n - m}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a^n}{b^n} = \left(\frac{a}{b}\right)^n</math-field>
        </p>
        <h3>negative Potenzen</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >a^{- n} = \frac{1}{a^n}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\left(\frac{a}{b}\right)^{- n} = \left(\frac{b}{a}\right)^n</math-field>
        </p>
        <h3>mehrere Potenzen</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >(a^n)^m = a^{n \cdot m}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >a^{n^m} = a^{(n^m)}</math-field>
        </p>
        <h3>Wurzeln</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >a^{\frac{1}{n}} = \sqrt[n]{a}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >a^{\frac{m}{n}} = \sqrt[n]{a^m} = \left(\sqrt[n]{a}\right)^m</math-field>
        </p>
        <h3>Konvention</h3>
        <p>
            <math-field default-mode="inline-math" style="display: inline-block;" read-only>a^0 = 1</math-field>
        </p>
    </div>
</template>
