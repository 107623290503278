<template>
    <header :style="{ opacity: opacity }">
        <h1 class="inset">
            <router-link to="/">
                <img src="../assets/logo.png" id="logo" alt="σ" class="header-logo" />
            </router-link>
        </h1>
    </header>
</template>

<script>
export default {
    data() {
        return {
            opacity: 1,
            ticking: false,
            bannerHeight: 200
        }
    },
    methods: {
        logoOpacity() {
            this.opacity = 0.02 * (this.bannerHeight - 55 - window.scrollY);
            this.ticking = false;
        },
        handleScroll() {
            if (!this.ticking) {
                window.requestAnimationFrame(this.logoOpacity);
                this.ticking = true;
            }
        }
    },
    mounted() {
        setInterval(
            function () {
                this.bannerHeight = document.getElementById("banner").offsetHeight
            }.bind(this),
            10
        );
    },
    beforeMount() {
        document.addEventListener('scroll', this.handleScroll)
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>

<style lang="scss" scoped>
header {
    height: 90px;
    width: 100%;
    position: fixed;
    display: inline-block;

    &.transparent {
        color: white;
        background-color: rgba(0, 0, 0, 0);
        border: solid 0.5px rgba(0, 0, 0, 0);
    }

    &.solid {
        color: black;
        background-color: $header-background;
        border: solid 0.5px $header-border;
    }
}

.header-logo {
    height: 35px;
}

.inset {
    margin: 25px;
}
</style>
