<template>
    <h1 class="inset title">Wurzeln</h1>
    <p
        class="inset description"
    >Bitte habe noch etwas Geduld. Hier entsteht bald ein passender Rechner.</p>
    <div class="box" type="mathlive">
        <h3>Multiplikation und Division</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\sqrt{a} \cdot \sqrt{b} = \sqrt{a \cdot b}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{\sqrt{a}}{\sqrt{b}} = \sqrt{\frac{a}{b}}</math-field>
        </p>
        <h3>Potenzen</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\sqrt[n]{a} = a^{\frac{1}{n}}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\sqrt[n]{a^m} = a^{\frac{m}{n}}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\sqrt[n]{a^{- m}} = \frac{1}{\sqrt[n]{a^m}} = \frac{1}{a^{\frac{m}{n}}} = a^{- \frac{m}{n}}</math-field>
        </p>
        <h3>Weitere Wurzeln</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\sqrt[n]{0} = 0</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\sqrt[2]{a} = \sqrt{a}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\sqrt[1]{a} = a</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\sqrt{a^2} = |a|</math-field>
        </p>
    </div>
</template>
