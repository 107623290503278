<template>
    <div class="banner-container" id="banner">
        <img src="../assets/banner.png" class="banner" />
    </div>
</template>

<style>
.banner {
    width: 100%;
    object-fit: fill;
}

.banner-container {
    width: 100%;
    max-height: 200px;
    overflow: hidden;
}
</style>
