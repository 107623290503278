<template>
    <h1 class="inset title">Logarithmus</h1>
    <p
        class="inset description"
    >Bitte habe noch etwas Geduld. Hier entsteht bald ein passender Rechner.</p>
    <div class="box">
        <h3>Definition</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >a^n = z \iff log_a(z) = n</math-field>
        </p>
        <h3>Rechenregeln</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >log_a(x) + log_a(y) = log_a(x \cdot y)</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >log_a(x) - log_a(y) = log_a\left(\frac{x}{y}\right)</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >log_a(x^y) = y \cdot log_a(x)</math-field>
        </p>
        <h3>Dekadischer und natürlicher Logarithmus</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >lg(x) = log_{10}(x)</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >ln(x) = log_e(x)</math-field>
        </p>
    </div>
</template>
