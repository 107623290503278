<template>
    <footer>
        <span>Tomathe</span>
        <span>
            <router-link to="/privacy-policy">Datenschutz</router-link>
        </span>
        <span>©Nicola Lorenz</span>
        <!-- <td>Für alle Anfragen wenden Sie sich bitte an nicola@loreny.de</td> -->
    </footer>
</template>

<style lang="scss">
footer {
    height: 180px;
    background-color: $footer-background;
    color: $almost-black;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
</style>
