<template>
    <h1 class="inset title">Bruchrechnung</h1>
    <p
        class="inset description"
    >Bitte habe noch etwas Geduld. Hier entsteht bald ein passender Rechner.</p>
    <div class="box">
        <h3>Kürzen</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a}{b} = \frac{a' \cdot e}{b' \cdot e} = \frac{a'}{b'}</math-field>
        </p>
        <h3>Erweitern</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a}{b} = \frac{a}{b} \cdot \frac{e}{e} = \frac{a \cdot e}{b \cdot e}</math-field>
        </p>
        <h3>Addieren</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a}{b} + \frac{c}{d} = \frac{a \cdot d}{b \cdot d} + \frac{c \cdot b}{d \cdot b} = \frac{a \cdot d + c \cdot b}{b \cdot d}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a}{b} + \frac{c}{b} = \frac{a + c}{b}</math-field>
        </p>
        <h3>Subtrahieren</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a}{b} - \frac{c}{d} = \frac{a \cdot d}{b \cdot d} - \frac{c \cdot b}{d \cdot b} = \frac{a \cdot d - c \cdot b}{b \cdot d}</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a}{b} - \frac{c}{b} = \frac{a - c}{b}</math-field>
        </p>
        <h3>Multiplizieren</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a}{b} \cdot \frac{c}{d} = \frac{a \cdot c}{b \cdot d}</math-field>
        </p>
        <h3>Dividieren</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >\frac{a}{b} \div \frac{c}{d} = \frac{a}{b} \cdot \frac{d}{c} = \frac{a \cdot d}{b \cdot c}</math-field>
        </p>
    </div>
</template>
