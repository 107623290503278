<template>
    <h1 class="inset title">Binomische Formeln</h1>
    <p
        class="inset description"
    >Bitte habe noch etwas Geduld. Hier entsteht bald ein passender Rechner.</p>
    <div class="box">
        <h3>1. Binomische Formel</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >(a + b)^2 = a^2 + 2ab + b^2</math-field>
        </p>
        <h3>2. Binomische Formel</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >(a - b)^2 = a^2 - 2ab + b^2</math-field>
        </p>
        <h3>3. Binomische Formel</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >(a + b) \cdot (a - b) = a^2 - b^2</math-field>
        </p>
    </div>
</template>
