import { createApp } from 'vue'
import App from './App.vue'
import * as MathLive from 'mathlive/dist/mathlive.min.mjs'
import VueMathfield from 'mathlive/dist/vue-mathlive.mjs'
import { createRouter, createWebHashHistory } from "vue-router";

//
import HomepageTomathe from "@/pages/homepage/HomepageTomathe.vue";
import PrivacyPolicy from "@/pages/privacy_policy/PrivacyPolicy.vue";

// Basics
import BinomialFormulas from "@/pages/binomial_formulas/BinomialFormulas.vue";
import FractionCalculator from "@/pages/fraction_calculator/FractionCalculator.vue";
import LogarithmCalculator from "@/pages/logarithm_calculator/LogarithmCalculator.vue";
import RootRules from "@/pages/root-rules/RootRules.vue";
import PowerLaws from "@/pages/power_laws/PowerLaws.vue";
import RuleOfThree from "@/pages/rule_of_three/RuleOfThree.vue";
import StandardCalculator from "@/pages/standard_calculator/StandardCalculator.vue";
import TermTransformations from "@/pages/term_transformations/TermTransformations.vue";
import UnitConverter from "@/pages/unit_converter/UnitConverter.vue";

//Geometry
import TriangleAllrounder from "@/pages/triangle_allrounder/TriangleAllrounder.vue";
import Figures2Dand3D from "@/pages/figures_2d_and_3d/Figures2Dand3D.vue";
import InterceptTheorem from "@/pages/intercept_theorem/InterceptTheorem.vue";


const routes = [
  //
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/', component: HomepageTomathe },
  //basics
  { path: '/binomial-formulas', component: BinomialFormulas },
  { path: '/fraction-calculator', component: FractionCalculator },
  { path: '/logarithm', component: LogarithmCalculator },
  { path: '/roots', component: RootRules },
  { path: '/power-laws', component: PowerLaws },
  { path: '/rule-of-three', component: RuleOfThree },
  { path: '/calculator', component: StandardCalculator },
  { path: '/basic-arithmetic-rules', component: TermTransformations },
  { path: '/unit-converter', component: UnitConverter },
  //geometry
  { path: '/triangle', component: TriangleAllrounder },
  { path: '/figures', component: Figures2Dand3D },
  { path: '/intercept-theorem', component: InterceptTheorem },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

createApp(App)
  .use(VueMathfield, MathLive)
  .use(router)
  .mount('#app')
