<template>
  <BaseHeader />
  <div class="aboveFooter">
    <BaseBanner />
    <router-view></router-view>
  </div>
  <BaseFooter />
</template>

<style lang="scss">
@import "@/assets/stylesheet.scss";
</style>

<style scoped>
.aboveFooter {
  min-height: calc(100vh - 80px);
}
</style>


<script>
import BaseHeader from '@/core/BaseHeader.vue'
import BaseBanner from '@/core/BaseBanner.vue'
import BaseFooter from '@/core/BaseFooter.vue'

export default {
  name: 'App',
  components: {
    BaseHeader,
    BaseBanner,
    BaseFooter,
  }
}

</script>
