<template>
    <h1 class="inset title">Grundrechenregeln</h1>
    <p
        class="inset description"
    >Bitte habe noch etwas Geduld. Hier entsteht bald ein passender Rechner.</p>
    <div class="box" type="mathlive">
        <h3>Kommutativgesetz</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >x + y = y + x</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >x \cdot y = y \cdot x</math-field>
        </p>
        <h3>Assoziativgesetz</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >x + (y + z) = (x + y) + z</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >x \cdot (y \cdot z) = (x \cdot y) \cdot z</math-field>
        </p>
        <h3>Distributivgesetz</h3>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >x \cdot (y + z) = x \cdot y + x \cdot z</math-field>
        </p>
        <p>
            <math-field
                default-mode="inline-math"
                style="display: inline-block;"
                read-only
            >(x + y) \cdot z = x \cdot z + y \cdot z</math-field>
        </p>
    </div>
</template>
