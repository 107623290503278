<template>
    <h1 class="inset title">Dreisatz</h1>
    <p class="inset description">Berechne ein Verhältnis anhand eines gegebenen Verhältnisses.</p>
    <div class="box">
        <p class="inputRow">
            <math-field
                class="input-math-field"
                id="up_left"
                v-model="up_left"
                virtual-keyboard-mode="manual"
            />≙
            <math-field
                class="input-math-field"
                id="up_right"
                v-model="up_right"
                virtual-keyboard-mode="manual"
            />
        </p>
        <p class="inputRow">
            <math-field
                class="input-math-field"
                id="down_left"
                v-model="down_left"
                virtual-keyboard-mode="manual"
            />≙
            <math-field
                class="input-math-field"
                id="down_right"
                v-model="down_right"
                virtual-keyboard-mode="manual"
            />
        </p>
        <p>
            <button @click="calculate" class="primary-button">Berechnen</button>
            <button @click="clear" class="secondary-button">Löschen</button>
        </p>
    </div>
    <p class="inset description">
        Trage Zahlen in drei Felder ein und drücke auf „Berechnen“. Im letzen Feld steht danach das Ergebnis.
        <br />Um alle Felder gleichzeitig zu leeren, klicke auf „Löschen“.
    </p>
</template>

<style scoped>
.inputRow {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}
</style>

<script>
import { create, all } from 'mathjs'

const mathDecimal = create(all)
mathDecimal.config({
    number: 'BigNumber',
    precision: 20
})
const mathRational = create(all)

let comma_character = ",";

export default {
    data() {
        return {
            up_left: "", up_right: "",
            down_left: "", down_right: ""
        }
    },
    methods: {
        // Returns a list of the four field as
        // [up_left, up_right, down_left, down_right]
        // normalized to use . as decimal punctuation.
        get_normalized_input() {
            let normalized_input = {}

            for (var field_name of ['up_left', 'up_right', 'down_left', 'down_right']) {
                let ascii_math = document
                    .getElementById(field_name)
                    .getValue('ascii-math');

                if (ascii_math.includes(",")) {
                    comma_character = ","
                } else if (ascii_math.includes(".")) {
                    comma_character = "."
                }

                normalized_input[field_name] = ascii_math.replace(",", ".");
            }

            return normalized_input;
        },
        calculate() {
            var normalized_input = this.get_normalized_input();

            let up_left = normalized_input['up_left']
            let up_right = normalized_input['up_right']
            let down_left = normalized_input['down_left']
            let down_right = normalized_input['down_right']

            if (up_left != "" && up_right != "" && down_left != "" && down_right == '') {
                this.down_right = this.rule_of_three(up_left, up_right, down_left, "oben links")
            } else if (up_left != "" && up_right != "" && down_right != "" && down_left == '') {
                this.down_left = this.rule_of_three(up_left, up_right, down_right, "oben rechs")
            } else if (up_left != "" && down_left != "" && down_right != "" && up_right == '') {
                this.up_right = this.rule_of_three(up_left, down_left, down_right, "unten links")
            } else if (up_right != "" && down_left != "" && down_right != "" && up_left == '') {
                this.up_left = this.rule_of_three(up_right, down_left, down_right, "unten rechts")
            }
        },
        clear() {
            this.up_left = "";
            this.up_right = "";
            this.down_left = "";
            this.down_right = "";
        },
        rule_of_three(x, y, z, position) {
            if (mathDecimal.simplify(x) != '0') {
                if ((x.includes("/") || Number.isInteger(Number(x)))
                    && (y.includes("/") || Number.isInteger(Number(y)))
                    && (z.includes("/") || Number.isInteger(Number(z)))) {
                    return mathRational.simplify(`((${y}) / (${x})) * (${z})`).toTex()
                } else {
                    // Use "," vs "."
                    return mathDecimal.simplify(`((${y}) / (${x})) * (${z})`).toTex().replace(".", comma_character);
                }
            } else {
                window.alert(`Bitte gib eine Zahl ungleich Null in das Feld ${position} ein.`)
            }
        }
    }
}
</script>
